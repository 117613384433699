// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content {
    margin-top: 90px;
}
.mandatory{
    color: red;
}

.MuiTablePagination-caption {
    padding: 0 8px;
  }
  .MuiTablePagination-root {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .MuiTablePagination-toolbar .MuiTypography-body2 {
    padding: 0 4px;
  }

  .MuiTablePagination-caption {
    font-size: 0.875rem; /* Or adjust as needed */
  }`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;AACA;IACI,UAAU;AACd;;AAEA;IACI,cAAc;EAChB;EACA;IACE,aAAa;IACb,uBAAuB;IACvB,mBAAmB;EACrB;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,mBAAmB,EAAE,wBAAwB;EAC/C","sourcesContent":[".content {\n    margin-top: 90px;\n}\n.mandatory{\n    color: red;\n}\n\n.MuiTablePagination-caption {\n    padding: 0 8px;\n  }\n  .MuiTablePagination-root {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n  \n  .MuiTablePagination-toolbar .MuiTypography-body2 {\n    padding: 0 4px;\n  }\n\n  .MuiTablePagination-caption {\n    font-size: 0.875rem; /* Or adjust as needed */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
