import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "../Components/Axios/Axios";
import { Container, Button, Card, Form, Col, Row } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Sidebar from "../Components/Sidebar/MainSidebar/Sidebar";
import Loader from "../Components/Spinner/Spinner";
import CKEditor from "ckeditor4-react-advanced";
import requests from "../Components/Requests/Requests";

function EditFaq(props) {
  const history = useHistory();
  const data = props.location.state;
  console.log(data.description,'rrrrrrrrrrrrrrrrr')
  const [id, setId] = useState(data.id.toString());
  const [title, setTitle] = useState(data.title);
  const [editorValue, setEditorValue] = useState(data.description);
  const [status, setStatus] = useState(data.status.toString());
  const [type, setType] = useState(data.faq_type);
  const [loading, setLoading] = useState(false);

  console.log("props.....", data);
  const addNewFaq = async () => {
    // const formData = new FormData();
    // formData.append("id", id);
    // formData.append("que", title);
    // formData.append("ans", editorValue);
   
    // formData.append("status", status);
    // formData.append("type",type);
    if(window.navigator.onLine)
    {
      setLoading(true);
      const body = {
        id: id.toString(),
        que: title,
        ans: editorValue,
        status: status.toString(),
        type: type,
      };
  
      try {
        const responce = await axios.post(requests.editFaq,body);
        // console.log("result........",responce);
        if (responce.data.Success === 1) {
          // console.log(responce);
          toast.success("FAQ editted successfully!!", {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setTimeout(() => {
            history.push("/allFaq");
          }, 2500);
        } else {
          setLoading(false);
          toast.warning(`Something went wrong`, {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } catch (err) {
        toast.warning(err, {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      // console.log("body.....", body);

    }
    else{
      toast.error(`No internet connection`, {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

    }
    
  
  };
  return (
    <>
      <Sidebar />
      <Container className="content">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Card>
          <Card.Header className="heading">Add FAQ</Card.Header>
          <Card.Body>
            <Row>
              <Col className="main">
                <Form.Group className="mb-3">
                  <Form.Label>
                    FAQ Title <span className="mandatory">*</span>
                  </Form.Label>
                  <Form.Control
                    value={title}
                    type="text"
                    placeholder="FAQ Title"
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Type</Form.Label>
                  {console.log(type)}
                  <Form.Select
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                  >
                    <option value="General">General</option>
                    <option value="Android">Android Web</option>
                    <option value="Android App">Android App</option>
                    <option value="Ios">Ios</option>
                    <option value="MacOS">Mac</option>
                  </Form.Select>
                </Form.Group>
                {type == "General" || type == "Android" ? (
                  <>
                    <Form.Group className="mb-3">
                      <Form.Label>FAQ Description</Form.Label>
                      <CKEditor
                        data={editorValue}
                        onChange={(evt) => setEditorValue(evt.editor.getData())}
                      />
                    </Form.Group>
                  </>
                ) : (
                  <>
                    <Form.Group className="mb-3">
                      <Form.Label>FAQ Description</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="FAQ Description"
                        onChange={(e) => setEditorValue(e.target.value)}
                      />
                    </Form.Group>
                  </>
                )}

                <Form.Group className="mb-3">
                  <Form.Label>
                    Status <span className="mandatory">*</span>
                  </Form.Label>
                  <Form.Select
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <option value="0">Inactive</option>
                    <option value="1">Active</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
          <Card.Footer className="footer">
            {loading == true ? (
              <Loader margin={0} />
            ) : (
              <Button
                className="submit-btn"
                onClick={() => addNewFaq()}
                variant="primary"
              >
                Submit
              </Button>
            )}
          </Card.Footer>
        </Card>
      </Container>
    </>
  );
}

export default EditFaq;
