import React, { forwardRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MaterialTable from "material-table";
import { ToastContainer, toast } from "react-toastify";
import { FaStar, FaStarHalfAlt, FaRegStar } from 'react-icons/fa';

import {
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
} from "@material-ui/icons";
import { Container, Button, Card } from "react-bootstrap";
import axios from "../Components/Axios/Axios";
//Custom Components Import
import Loader from "../Components/Spinner/Spinner";
import Sidebar from "../Components/Sidebar/MainSidebar/Sidebar";
import requests from "../Components/Requests/Requests";
const StarRating = ({ rating }) => {
  const stars = [];
  const fullStars = Math.floor(rating);
  const halfStars = rating % 1 >= 0.5 ? 1 : 0;
  const emptyStars = 5 - fullStars - halfStars;

  for (let i = 0; i < fullStars; i++) {
    stars.push(<FaStar key={`full-${i}`} color="#FFD700" />);
  }
  for (let i = 0; i < halfStars; i++) {
    stars.push(<FaStarHalfAlt key={`half-${i}`} color="#FFD700" />);
  }
  // for (let i = 0; i < emptyStars; i++) {
  //   stars.push(<FaRegStar key={`empty-${i}`} color="#FFD700" />);
  // }

  return <div>{stars}</div>;
};

function AllFeedback() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
 

  const tableIcons = {
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };
  

  useEffect(() => {
    getAllFeedback();
  }, []);
  const handleDownload = (rowData) => {
  const downloadUrl = `https://devapi.unmix-app.com/api/v1/copyfiles?id=-1&folder=7825586&file=vocals.mp3&isdemo=0&bucket_id=5`;

  // const downloadUrl = 'https://devapi.unmix-app.com/api/v1/copyfiles?id=-1&folder=7825586&file=vocals.mp3&isdemo=0&bucket_id=5';
    
    // Open the download URL in a new tab
    window.open(downloadUrl);

};
  const getAllFeedback = async () => {
    setLoading(true);
    try {
      const responce = await axios.get(requests.allFeedback);
      // console.log(responce);
      if (responce.data.Success === 1) {
        setData(responce.data.data);
        setLoading(false);
      } else {
        toast.warning(`Something went wrong`, {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (err) {
      toast.warning(err, {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  return (
    <>
      <Sidebar />
      <Container className="content">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Card className="card">
          <Card.Header className="heading">
            <div
              style={{ display: "flex", justifyContent: "space-between" }}
              className="inside-header"
            >
              All FEEDBACK
              {/* <Link to="/addFaq">
                <Button className="table-button" variant="primary" size="md">
                  Add FEEDBACK
                </Button>
              </Link> */}
            </div>
          </Card.Header>
          <Card.Body className="body">
            {loading == true ? (
              <Loader margin={"auto"} />
            ) : (
              <MaterialTable
                data={data}
                icons={tableIcons}
                columns={[
                  {
                    title: "#",
                    field: "id",
                    render: (rowData) => rowData.tableData.id + 1,
                    cellStyle: {
                      borderRight: "1px solid #e0e0e0",
                      borderLeft: "1px solid #e0e0e0",
                      wordBreak: "break-all",
                    },
                  },
                  {
                    title: "user_id",
                    field: "uid",
                    cellStyle: {
                      borderRight: "1px solid #e0e0e0",
                      wordBreak: "break-all",
                    },
                  },
                  {
                    title: "Request_id",
                    field: "file_id",
                    cellStyle: {
                      borderRight: "1px solid #e0e0e0",
                      wordBreak: "break-all",
                    },
                  },
                  {
                    title: "Country_code",
                    field: "country_code",
                    cellStyle: {
                      borderRight: "1px solid #e0e0e0",
                      wordBreak: "break-all",
                    },
                  },
                  {
                    title: "Language_code",
                    field: "language_code",
                   
                    cellStyle: {
                      borderRight: "1px solid #e0e0e0",
                    },
                  },
                  {
                    title: "Stems",
                    field: "stems",
                    cellStyle: {
                      borderRight: "1px solid #e0e0e0",
                    },
                  },
                  {
                    title: "Ratings",
                    field: "ratings",
                    cellStyle: {
                      borderRight: "1px solid #e0e0e0",
                    },
                  render: rowData => <StarRating rating={rowData.ratings} />,
                  },
                  {
                    title: "Feedback",
                    field: "feedback",
                    cellStyle: {
                      borderRight: "1px solid #e0e0e0",
                    },
                  },
                  {
                    title: "Added_date",
                    field: "formatted_date",
                    cellStyle: {
                      borderRight: "1px solid #e0e0e0",
                    },
                   
                  },                    
                  {
                    title: "Message",
                    field: "message",
                    cellStyle: {
                      borderRight: "1px solid #e0e0e0",
                    },
                  },
                  {
                    title: "File_path",
                    field: "filepathyyy",
                    cellStyle: {
                      borderRight: "1px solid #e0e0e0",
                    },
                  },
                  {
                    title: "Download",
                    render: (rowData) => (
                      rowData.ratings==1?
                      <Button
                        variant="primary"
                        size="md"
                        onClick={() => handleDownload(rowData)}
                      >
                        Download
                      </Button>:''
                    ),
                  },
                  
                  
                  // ,
                  
                  // {
                  //   title: "Edit",
                  //   render: (rowData) => (
                  //     <Link to={{ pathname: "/editFaq", state: rowData }}>
                  //       <Button variant="primary" size="md">
                  //         Edit
                  //       </Button>
                  //     </Link>
                  //   ),
                  // },
                ]}
                options={{
                  pageSize: 15,
                  pageSizeOptions: [
                    25, 40,
                    // { value: data.length, label: data.length },
                  ],
                  exportButton: true,
                  showTitle: false,
                  // pageSize: 10,
                }}
              />
            )}
          </Card.Body>
        </Card>
      </Container>
    </>
  );
}

export default AllFeedback;
