import React, { useEffect, useState } from "react";
import axios from "../Components/Axios/Axios";
import { Container, Button, Card, Form, Col, Row } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Sidebar from "../Components/Sidebar/MainSidebar/Sidebar";
import CKEditor from "ckeditor4-react-advanced";
import Loader from "../Components/Spinner/Spinner";
import requests from "../Components/Requests/Requests";
import instance from "../Components/Axios/Axios";
import { useHistory } from "react-router-dom";

function EditBlogs(props) {
  const history = useHistory();
  const [id, setId] = useState(props.location.state.id);
  const [email, setEmail] = useState("");
  const [credits, setCredits] = useState("");
  const [premcreds,setPremCreds]=useState("")
  const [blogSlug, setBlogSlug] = useState("");
  const [shortDesc, setShortDesc] = useState("");
  const [editorValue, setEditorValue] = useState(
    props.location.state.desciption
  );
  const [status, setStatus] = useState(props.location.state.status);
  const [loading, setloading] = useState(false);

  const [title, setTitle] = useState("");
  const [blogImg, setBlogImg] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDesc, setMetaDesc] = useState("");


  let data = props.location.state;
  console.log("data......", data);

  useEffect(() => {
    
    
    console.log(data,'data12321')
    defaultValues();
    
  }, []);

  useEffect(()=>{
    console.log(credits,'credits')
  },[credits])
  const defaultValues = () => {

    setEmail(data.email);
    setCredits(data.credits);
    setPremCreds(data.premium_credits);
  };

  const editUserApi = async () => {
    if(window.navigator.onLine)
    {
      console.log(data.credits,data.premium_credits,credits,premcreds,'heyyyyyyyy')
      if (isNaN(credits) || isNaN(premcreds)||credits===''||premcreds==='') {
        toast.warning(`Credits or Premium Credits value cannot be null or invalid`, {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      else if(data.credits===credits && data.premium_credits===premcreds)
      {
        toast.warning(`Please update credits values`, {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
  
      }
      else{
        setloading(true);
      const body = {
        id: id.toString(),
        email: email,
        credits: credits.toString(),
        premium_creds: premcreds.toString(),
      };
  
      try {
        const responce = await axios.post(requests.editUser,body);
        // console.log("result........",responce);
        if (responce.data.Success === 1) {
          // console.log(responce);
          toast.success("Credits updated successfully!!", {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setTimeout(() => {
            history.push("/allUsers");
          }, 2500);
        } else {
          setloading(false);
          toast.warning(`Something went wrong`, {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } catch (err) {
        toast.warning(err, {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
  

    }
   
   
    }
    else{
      toast.error(`No internet connection`, {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

 
    // const formData = new FormData();
    // formData.append("id", id);
    // formData.append("que", title);
    // formData.append("ans", editorValue);
   
    // formData.append("status", status);
    // formData.append("type",type);
    }
    // console.log("body.....", body);
  return (
    <div>
      <Sidebar />
      <Container className="content">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Card>
          <Card.Header className="heading">Edit User Credits</Card.Header>
          <Card.Body>
            <Row>
              <Col className="main">
              <Form.Group className="mb-3">
                  <Form.Label>Id</Form.Label>
                  <Form.Control
                    defaultValue={id}
                    type="text"
                    readOnly
                    placeholder="user id"
                    // onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    defaultValue={email}
                    type="text"
                    readOnly
                    placeholder="Email"
                    // onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
  <Form.Label>Simple Credits</Form.Label>
  <Form.Control
    value={credits} // Use value instead of defaultValue to bind state
    type="number"
    placeholder="credits"
    onChange={(e) => {
      const value = e.target.value;
      setCredits(value);
    }}
  />
</Form.Group>
<Form.Group className="mb-3">
  <Form.Label>Premium Credits</Form.Label>
  <Form.Control
    value={premcreds} // Use value instead of defaultValue to bind state
    type="number"
    placeholder="Premium credits"
    onChange={(e) => {
      const value = e.target.value;
      setPremCreds(value);
    }}
  />
</Form.Group>
              </Col>
            </Row>
          </Card.Body>
          <Card.Footer className="footer">
            {loading == true ? (
              <Loader margin={0} />
            ) : (
              <Button
                className="submit-btn"
                onClick={() => editUserApi()}
                variant="primary"
              >
                Submit
              </Button>
            )}
          </Card.Footer>
        </Card>
      </Container>
    </div>
  );
}

export default EditBlogs;
