import React, { forwardRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MaterialTable from "material-table";
import { ToastContainer, toast } from "react-toastify";
import { FaStar, FaStarHalfAlt, FaRegStarimport ,FaMicrophone,FaMusic,FaDrum} from 'react-icons/fa';
import Modal from '../Components/Popup/Popup'
import { MdEqualizer } from 'react-icons/md';
import { GiGuitarBassHead } from "react-icons/gi";
import { PiGuitarFill } from "react-icons/pi";
import style from '../Components/Popup/Popup.css'

import {
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
} from "@material-ui/icons";
import { TextField } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { Container, Button, Card } from "react-bootstrap";
import axios from "../Components/Axios/Axios";
import axios1 from 'axios'
// Custom Components Import
import Loader from "../Components/Spinner/Spinner";
import Sidebar from "../Components/Sidebar/MainSidebar/Sidebar";
import requests from "../Components/Requests/Requests";
import { InputAdornment} from "@material-ui/core";
import { AccountCircle } from "@material-ui/icons";
const StarRating = ({ rating }) => {
  const stars = [];
  const fullStars = Math.floor(rating);
  const halfStars = rating % 1 >= 0.5 ? 1 : 0;
  const emptyStars = 5 - fullStars - halfStars;

  for (let i = 0; i < fullStars; i++) {
    stars.push(<FaStar key={`full-${i}`} color="#FFD700" />);
  }
  for (let i = 0; i < halfStars; i++) {
    stars.push(<FaStarHalfAlt key={`half-${i}`} color="#FFD700" />);
  }
  // for (let i = 0; i < emptyStars; i++) {
  //   stars.push(<FaRegStar key={`empty-${i}`} color="#FFD700" />);
  // }

  return <div>{stars}</div>;
};

const useStyles = makeStyles(() => ({
  noBorder : {
    border: "none",
    borderRadius: '5px',
  },
}));

function AllBlogs() {
  const [openmodal,setOpenModal]=useState(false)
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0); // MaterialTable uses 0-based page indexing
  const [totalCount, setTotalCount] = useState(0); // Total count of entries
  const [searchValue, setSearchValue] = useState("");
  const [karoakeData,setKaroakeData]=useState([]);
  const [currentBucket,setCurrentBucket]=useState(0);
  const [currentFileid,setCurrentFileid]=useState(0)

  const tableIcons = {
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  useEffect(() => {
    // Fetch data whenever the page changes
    if (searchValue === '') {
      fetchData(page + 1); // Fetch data for the current page
    } else {
      fetchDataSearch(searchValue, page + 1); // Fetch search data for the current page
    }
  }, [page]); // Triggered when page changes

  const handleDownload = (rowData,filename,is_aws_storage) => {
    console.log(rowData,'rowdata')
    if (rowData.is_aws_storage==undefined)
      {
        const downloadUrl = `https://devapi.unmix-app.com/api/v1/copyfiles?id=-1&folder=${currentFileid}&file=${filename}&isdemo=0&bucket_id=${is_aws_storage}`;
  
        // const downloadUrl = 'https://devapi.unmix-app.com/api/v1/copyfiles?id=-1&folder=7825586&file=vocals.mp3&isdemo=0&bucket_id=5';
          
          // Open the download URL in a new tab
          window.open(downloadUrl);

      } 
      else{
        const downloadUrl = `https://devapi.unmix-app.com/api/v1/copyfiles?id=-1&folder=${rowData.file_id}&file=${filename}&isdemo=0&bucket_id=${rowData.is_aws_storage}`;
  
        // const downloadUrl = 'https://devapi.unmix-app.com/api/v1/copyfiles?id=-1&folder=7825586&file=vocals.mp3&isdemo=0&bucket_id=5';
          
          // Open the download URL in a new tab
          window.open(downloadUrl);

      }
   
  
  };
  const functiongetsong=async(row)=>{
      const response1 = await axios1.get("https://devapi.unmix-app.com/api/v1/admin/getsongfiles", {
        params: { id: row.file_id ,bucket_id:row.is_aws_storage}
    });
     // Process each response as needed
    if(response1.data.message=="found")
    {
      // console.log(response1,'success')
      setKaroakeData(response1.data.files.files)
      setCurrentBucket(row.is_aws_storage)
      setCurrentFileid(row.file_id)
      setOpenModal(true)

    }
    else{
      
      toast.error("File not present in bucket")
    }
  }

  const fetchData = async (currentPage) => {
    setLoading(true);
    try {
      const response = await axios.get(requests.allFeedback1, {
        params: { page: currentPage }, // Pass 1-based page number to the server
      });
      if (response.data.Success === 1) {
        setData(response.data.data); // Set new data
        console.log(response.data.data,'get')
       // Assuming response.data.data is the array of objects you received
const rows = response.data.data;

// Filter rows with stems value of 120
const filteredRows = rows.filter(row => row.stems === 4);

// Make an API call for each filtered row
for (const row of filteredRows) {
    // const response1 = await axios.get("https://devapi.unmix-app.com/api/v1/admin/getsongfiles", {
    //     params: { id: row.file_id }
    // });
    // console.log(response1.data); // Process each response as needed
}
       console.log(response.data,'response')
        setTotalCount(response.data.totalCount); // Set total count
      } else {
        toast.warning(`Something went wrong`, {
          position: "top-right",
          autoClose: 2500,
        });
      }
    } catch (err) {
      toast.warning(err.message, {
        position: "top-right",
        autoClose: 2500,
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchDataSearch = async (searchValue, currentPage) => {
    setLoading(true);
    try {
      const response = await axios.get(requests.filterUsers, {
        params: { page: currentPage, search: searchValue }, // Pass 1-based page number and search term to the server
      });
      if (response.data.Success === 1) {
        setData(response.data.data); // Set new data
        setTotalCount(response.data.totalCount); // Set total count
      } else {
        toast.warning(`Something went wrong`, {
          position: "top-right",
          autoClose: 2500,
        });
      }
    } catch (err) {
      toast.warning(err.message, {
        position: "top-right",
        autoClose: 2500,
      });
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (newPage) => {
    setPage(newPage); // Update current page
  };

  const handleSearch = () => {
    // Trigger the search API call when the button is clicked
    setPage(0); // Reset to the first page
    // fetchDataSearch(searchValue, 1); // Fetch search results for the first page
    if (searchValue === '') {
      fetchData(1); // Fetch data for the current page
    } else {
      fetchDataSearch(searchValue, 1); // Fetch search data for the current page
    }
  };

  return (
    <>
      <Sidebar />
      <Container className="content">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
        />
        <Card className="card">
          <Card.Header className="heading">
            <div
              style={{ display: "flex", justifyContent: "space-between",alignItems:"ce" }}
              className="inside-header"
            >
             All FEEDBACK
             
            </div>
          </Card.Header>
          <Card.Body className="body">
            {loading ? (
              <Loader margin={"auto"} />
            ) : (
              <MaterialTable
                data={data} // Data to be displayed in the table
                icons={tableIcons}
                columns={[
                  {
                    title: "#",
                    field: "id",
                    render: (rowData) => rowData.tableData.id + 1,
                    cellStyle: {
                      borderRight: "1px solid #e0e0e0",
                      borderLeft: "1px solid #e0e0e0",
                      wordBreak: "break-all",
                    },
                  },
                  {
                    title: "user_id",
                    field: "uid",
                    cellStyle: {
                      borderRight: "1px solid #e0e0e0",
                      wordBreak: "break-all",
                    },
                  },
                  {
                    title: "Request_id",
                    field: "file_id",
                    cellStyle: {
                      borderRight: "1px solid #e0e0e0",
                      wordBreak: "break-all",
                    },
                  },
                  {
                    title: "Country_code",
                    field: "country_code",
                    cellStyle: {
                      borderRight: "1px solid #e0e0e0",
                      wordBreak: "break-all",
                    },
                  },
                  {
                    title: "Language_code",
                    field: "language_code",
                   
                    cellStyle: {
                      borderRight: "1px solid #e0e0e0",
                    },
                  },
                  {
                    title: "Stems",
                    field: "stems",
                    cellStyle: {
                      borderRight: "1px solid #e0e0e0",
                    },
                  },
                  {
                    title: "Ratings",
                    field: "ratings",
                    cellStyle: {
                      borderRight: "1px solid #e0e0e0",
                    },
                  render: rowData => <StarRating rating={rowData.ratings} />,
                  },
                  {
                    title: "Feedback",
                    field: "feedback",
                    cellStyle: {
                      borderRight: "1px solid #e0e0e0",
                    },
                  },
                  {
                    title: "Added_date",
                    field: "formatted_date",
                    cellStyle: {
                      borderRight: "1px solid #e0e0e0",
                    },
                   
                  },                    
                  // {
                  //   title: "Message",
                  //   field: "message",
                  //   cellStyle: {
                  //     borderRight: "1px solid #e0e0e0",
                  //   },
                  // },
                  {
                    title: "File_path",
                    field: "filepath",
                    cellStyle: {
                      borderRight: "1px solid #e0e0e0",
                    },
                  },
                  {
                    title: "Download",
                    render: (rowData) => (
                      (rowData.ratings==1 && rowData.stems==2)?
                      <>
                      {/* 2 -stems */}
                      <div  className="btnPopUp">
                      <Button
                       className="getStartBtn"
                        variant="primary"
                        size="md"
                        onClick={() => handleDownload(rowData,"vocals.mp3")}
                      >
                      < FaMicrophone/>
                      </Button>
                      <Button
                       className="getStartBtn"
                        variant="primary"
                        size="md"
                        onClick={() => handleDownload(rowData,"instrumental.mp3")}
                      >
                      < FaMusic/>
                      </Button>
                      </div>
                      </>
                      : (rowData.ratings==1 && rowData.stems==4)?<>
                      {/* 4-stems */}
                      <div  className="btnPopUp">
                      <Button
                       className="getStartBtn"
                        variant="primary"
                        size="md"
                        onClick={() => handleDownload(rowData,"vocals.mp3")}
                      >
                      < FaMicrophone/>
                      </Button>
                      <Button
                       className="getStartBtn"
                        variant="primary"
                        size="md"
                        onClick={() => handleDownload(rowData,"drums.mp3")}
                      >
                     <FaDrum/>
                      </Button>
                      <Button
                       className="getStartBtn"
                        variant="primary"
                        size="md"
                        onClick={() => handleDownload(rowData,"bass.mp3")}
                      >
                      <GiGuitarBassHead/>
                      </Button>
                      <Button
                       className="getStartBtn"
                        variant="primary"
                        size="md"
                        onClick={() => handleDownload(rowData,"other.mp3")}
                      >
                      Others
                      </Button>
                      </div>

                      </>: (rowData.ratings==1 && rowData.stems==5)?
                      <>
                      
                       <div  className="btnPopUp">
                      <Button
                       className="getStartBtn"
                        variant="primary"
                        size="md"
                        onClick={() => handleDownload(rowData,"vocals.mp3")}
                      >
                      < FaMicrophone/>
                      </Button>
                      <Button
                       className="getStartBtn"
                        variant="primary"
                        size="md"
                        onClick={() => handleDownload(rowData,"drums.mp3")}
                      >
                     Drums
                      </Button>
                      <Button
                       className="getStartBtn"
                        variant="primary"
                        size="md"
                        onClick={() => handleDownload(rowData,"bass.mp3")}
                      >
                      <GiGuitarBassHead/>
                      </Button>
                      <Button
                       className="getStartBtn"
                        variant="primary"
                        size="md"
                        onClick={() => handleDownload(rowData,"other.mp3")}
                      >
                      Others
                      </Button>
                      <Button
                       className="getStartBtn"
                        variant="primary"
                        size="md"
                        onClick={() => handleDownload(rowData,"guitar.mp3")}
                      >
                      <PiGuitarFill/>
                      </Button></div></>:(rowData.ratings==1 && rowData.stems==6)?<>
                      <div  className="btnPopUp">
                      <Button
                       className="getStartBtn"
                        variant="primary"
                        size="md"
                        onClick={() => handleDownload(rowData,"vocals.mp3")}
                      >
                      < FaMicrophone/>
                      </Button>
                      <Button
                       className="getStartBtn"
                        variant="primary"
                        size="md"
                        onClick={() => handleDownload(rowData,"drums.mp3")}
                      >
                     Drums
                      </Button>
                      <Button
                       className="getStartBtn"
                        variant="primary"
                        size="md"
                        onClick={() => handleDownload(rowData,"bass.mp3")}
                      >
                      <GiGuitarBassHead/>
                      </Button>
                      <Button
                       className="getStartBtn"
                        variant="primary"
                        size="md"
                        onClick={() => handleDownload(rowData,"other.mp3")}
                      >
                      Others
                      </Button>
                      <Button
                       className="getStartBtn"
                        variant="primary"
                        size="md"
                        onClick={() => handleDownload(rowData,"guitar.mp3")}
                      >
                      <PiGuitarFill/>
                      </Button>
                      <Button
                       className="getStartBtn"
                        variant="primary"
                        size="md"
                        onClick={() => handleDownload(rowData,"piano.mp3")}
                      >
                      Piano
                      </Button></div></>:(rowData.ratings==1 && rowData.stems==120)?
                     <div className="downloadbtnsingle">
                      <Button
                       
                        className="getStartBtn"
                        variant="primary"
                        size="md"
                        onClick={() =>{
                          functiongetsong(rowData)
                         }}
                      >
                      Download
                     
                      </Button>
                      </div>:""
                    ),
                  },
                
                  
                  
                  // ,
                  
                  // {
                  //   title: "Edit",
                  //   render: (rowData) => (
                  //     <Link to={{ pathname: "/editFaq", state: rowData }}>
                  //       <Button variant="primary" size="md">
                  //         Edit
                  //       </Button>
                  //     </Link>
                  //   ),
                  // },
                ]}
                options={{
                  pageSize: 30, // Set default rows per page to 30
                  paginationType: "normal", // Use a stepped pagination style
                  showTitle: false,
                  pagination: true,
                  pageSizeOptions: false, // Disable page size selector
                  tableLayout: "auto",
                  search:false,
                  exportButton: true,
                  showTitle: false,
                }}
                totalCount={totalCount} // Total number of rows (from backend)
                page={page} // Current page number (state)
                onChangePage={(newPage) => handlePageChange(newPage)} // Handle page change event
                localization={{
                  pagination: {
                    labelRowsSelect: "rows",
                    labelDisplayedRows: "{from}-{to} of {count}",
                  },
                }}
              />
            )}

          </Card.Body>
        </Card>
      </Container>
      <Modal isOpen={openmodal} onClose={()=>setOpenModal(false)}>
      {/* <div className="btnPopUp"> */}
      <h3>File List</h3>
      {karoakeData.map((item)=>{
      return(<>
      {console.log(item,'item')}
      <Button
      className="getStartBtn"
        variant="primary"
        size="md"
        onClick={() => handleDownload(item,item.name,currentBucket)}
                      >
                      {item.name}
                      </Button></>
                     )})
      }

                </Modal>
    </>
  );
}

export default AllBlogs;
